import api from './axios'

import qs from 'qs'
import Toast from '@/components/UI/Toast'
import router from '@/router'
//  登录成功处理


function wechatLogin(code?: string, state?: string) {

    const href = window.location.href
    const codeReg = /code=(.*?)&/
    const stateReg = /state=(.*)/

    if (href.match(codeReg)) {
        code = RegExp.$1
    }
    if (href.match(stateReg)) {
        state = RegExp.$1
    }



    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        if (token) {
            //  如果token 存在 去取用户信息提供给 personal 页面
            api
                .get("/M/User/isLogin", { headers: { token } })
                .then((res) => {
                    if (res.data.success) {
                        resolve(res)
                    } else {
                        Toast({
                            type: 'error',
                            title: "登录失败",
                            onClose: () => {
                                localStorage.removeItem('token')
                                router.push({
                                    name: "Login"
                                })
                            }
                        })
                        resolve(res)
                    }
                })
                .catch((e) => {
                    resolve('err')
                    console.log(e);
                });
            return false
        }

        if (!code) {

            resolve('err')
            return false
        }
        api.post('/M/Payment/WechatTest', qs.stringify({
            code,
            state
        })).then((res) => {
            
            if (res.data.success) {
                localStorage.setItem('token', (res.data.token))
                window.location.reload()
                resolve(res)
            } else {
                Toast({
                    type: 'error',
                    title: "登录失败",
                    onClose: () => {
                        localStorage.removeItem('token')
                        router.push({
                            name: "Login"
                        })
                    }
                })
                resolve(res)
            }
        }).catch(() => {
            reject('err')
        })
    })
}
export const wechatLoginMine = function (code?: string, state?: string) {
    const href = window.location.href
    const codeReg = /code=(.*?)&/
    const stateReg = /state=(.*)/
    if (href.match(codeReg)) {
        code = RegExp.$1
    }
    if (href.match(stateReg)) {
        state = RegExp.$1
    }

    console.log("===========================wechatLoginMine:code================"+code)

    return new Promise((resolve, reject) => {
        if (localStorage.getItem('token')) {
            resolve('success')
            return false
        }
        if (!code) {
            resolve('err')
            return false
        }
        api.post('/M/User/WeixinLoginCallBack', qs.stringify({
            code,
            state
        })).then((res) => {
            if (res.data.success) {
                // localStorage.setItem('token', res.data.token)
                const data = res.data;
                if (data.token) {
                    localStorage.setItem('token', res.data.token)
                    Toast({
                        type: "success",
                        title: res.data.msg
                    });
                    resolve(res)
                }else{
                    if(data.unionid){
                        router.push({ name: "InitTel" })
                    } else {
                        Toast({
                            type: "warning",
                            title: "请先关注普天同创公众号"
                        });
                    }
                }
            } else {
                const routeName = (router.currentRoute as any)._value.name
                if (routeName == 'NewsDetail' || routeName == 'TopicDetail' || routeName == 'livedetail' || routeName == "videodetail") {
                    return false
                } else {
                    Toast({
                        type: 'error',
                        title: "登录失败",
                        onClose: () => {
                            localStorage.removeItem('token')
                            router.push({
                                name: "Login"
                            })
                        }
                    })
                }
                reject('err')
            }

        }).catch(e => {
            reject('err')
        })

    })
}


export default wechatLogin
