<template>
  <router-view></router-view>
  <teleport to="#login">
    <menu-left :isMenuHide="isMenuHide"></menu-left>
  </teleport>
</template>


<script lang="ts">
import MenuLeft from "@/components/menu/MenuLeft.vue";
import {
  defineComponent,
  ref,
  provide,
  watch,
  reactive,
  readonly,
  watchEffect,
  nextTick,
  onBeforeUpdate,
  toRefs,
  onMounted,
} from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";

import useLogin from "@/hooks/useLogin";
import { useStore } from "vuex";
import $ from "jquery";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import wx from "weixin-sdk-js";
import { wechatLoginMine } from "@/api/wechatLogin";
export default defineComponent({
  components: {
    MenuLeft,
  },
  setup() {
    const isHeaderHide = ref(false);
    const isMenuHide = ref(false);
    const route = reactive(useRoute());
    const router = useRouter();
    const toRoute = ref<RouteLocationNormalized | null>(null);
    const fromRoute = ref<RouteLocationNormalized | null>(null);
    function handleHide(value: boolean) {
      if (isHeaderHide.value && fromRoute.value) {
        router.push({
          name: fromRoute.value.name as string,
          query: fromRoute.value.query,
        });
      }
      isHeaderHide.value = !isHeaderHide.value;
    }

    provide("handleHide", handleHide);
    provide("isHeaderHide", isHeaderHide.value);

    function handleMenuHide() {
      isMenuHide.value = !isMenuHide.value;
    }
    const store = useStore();

    provide("handleMenuHide", handleMenuHide);

    // watch(route, () => {

    //   router.beforeEach((to, from, next) => {
    //     if (from.params.validate) {
    //       toRoute.value = to;
    //       fromRoute.value = from;
    //     }
    //     if (isHeaderHide.value) {
    //       isHeaderHide.value = false;
    //     }

    //     if (to.meta.Authentication) {
    //       const token = localStorage.getItem("token");
    //       if (token) {
    //         next();
    //       } else {
    //         const name = from.name;
    //         const query = from.query;
    //         const params = Object.assign(from.params, { valid: true });
    //         //  路由跳转不相同时记录要跳转的路由
    //         if (to.name !== from.name) {
    //           toRoute.value = to;
    //           fromRoute.value = from;
    //         }
    //         // if (to.name !== from.name) {
    //         //   store.commit("UPDATE_TO_ROUTER", to);
    //         //   store.commit("UPDATE_FROM_ROUTER", from);
    //         // }
    //         next({
    //           name: name as string,
    //           query,
    //           params: from.params,
    //         });
    //       }
    //     } else {
    //       next();
    //     }
    //   });

    // router.beforeEach((to, from, next) => {
    //   if (isHeaderHide.value) {
    //     isHeaderHide.value = false;
    //     router.push({ path: from.path, query: from.query });
    //   }
    //   next();
    // });
    // });
    const isweixin = ref(false);
    const ua = navigator.userAgent.toLowerCase();
    const reg = /MicroMessenger/i;
    isweixin.value = reg.test(ua);
    onMounted(() => {
      if (isweixin.value) {
        nextTick(() => {
          const link = window.location.href;
          const title = document.title;
          const desc = (document.querySelectorAll(
            "meta[name=description]"
          )[0] as any).content;
          console.log(desc);
    
          const formData = new FormData();
          formData.append("url", link);
          axios.post("/M/Server/getweixin", formData).then((res: any) => {
            const data = res.data;
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.appid, // 必填，公众号的唯一标识
              timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.str, // 必填，签名
              jsApiList: [
                "checkJsApi",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {
              //需在用户可能点击分享按钮前就先调用
    
              wx.updateAppMessageShareData({
                title: document.title, // 分享标题
                desc: (document.querySelectorAll(
                  "meta[name=description]"
                )[0] as any).content, // 分享描述
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://m.gbw114.com/img/wxshare/logo.png", // 分享图标
                success: function () {
                  // 设置成功
                },
              });
              wx.updateTimelineShareData({
                title: document.title, // 分享标题
                desc: (document.querySelectorAll(
                  "meta[name=description]"
                )[0] as any).content, // 分享描述
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "http://m.gbw114.com/img/wxshare/logo.png", // 分享图标
                success: function () {
                  // 设置成功
                },
              });
            });
          });
        });
      }
    });
    watch(
      () => {
        return route.params;
      },
      (to, from) => {
        if (to.valid || from.validate) {
          isHeaderHide.value = true;
        }
      }
    );

    watch(
      () => {
        return isHeaderHide.value;
      },
      () => {
        if (isHeaderHide.value) {
          $("body").css({
            overflow: "hidden",
          });
        } else {
          $("body").css({
            overflow: "auto",
          });
        }
      }
    );

    //  用户登录信息控制

    const userInfo = reactive({});

    function updateUserInfo(data: object) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          userInfo[key] = data[key];
        }
      }
    }
    function clearUserInfo() {
      Object.keys(userInfo).forEach((item) => {
        delete userInfo[item];
      });
    }
    function getUserInfo() {
      const token = localStorage.getItem("token") || "";

      axios
        .get("/M/User/isLogin", { headers: { token } })
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem("isLogin", "true");
            updateUserInfo(res.data.obj);
          } else {
            localStorage.removeItem("token");
            // console.log("token", 123123123123123);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getUserInfo();
    provide("userInfo", readonly(userInfo));
    provide("UPDATE_USERINFO", updateUserInfo);
    provide("CLEAR_USERINFO", clearUserInfo);

    function loginSuccess() {
      router.push({
        name: toRoute.value?.name as string,
        query: toRoute.value?.query,
        params: toRoute.value?.params,
      });
      isHeaderHide.value = false;
    }

    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

    watch(
      () => route.path,
      (to, from) => {
        if (to == '/order' || to == '/mine' || to == '/bill' || to == '/invoice' || to == '/intergral' || to == '/account' || to=='/wechatred' || to=='/' || to=='/product') {
          // wechatLogin(
          //   route.query.code as string,
          //   route.query.state as string
          // ).then((res: any) => {
          //   if (typeof res === "object" && res.data.success) {
          //     updateToken(res.data.token);
          //     updateUserInfo(res.data.obj);
          //     getUserInfo();
          //   }
          // });
        } else {
          wechatLoginMine(
                  route.query.code as string,
                  route.query.state as string
          ).then((res: any) => {
            if (typeof res === "object" && res.data.success) {
              updateToken(res.data.token);
              updateUserInfo(res.data.obj);
            }
          });
        }
      }
    );

    return {
      isweixin,
      isHeaderHide,
      handleHide,
      isMenuHide,
      loginSuccess,
      toRoute,
      fromRoute,
      userInfo,
    };
  },
});
</script>
<style lang="scss">
@import url("./assets/styles/reset.scss");

// #app {
//   padding-top: 1.467rem;
// }
</style>
