export default  {
    "Index": "http://www.gbw114.com/",
    "Interest": "http://www.gbw114.com/",
    "News": "http://www.gbw114.com/nlist/nlist_0_1.html",
    "NewsDetail": "http://www.gbw114.com/news/n{{newsid}}.html",
    "DocDetail": "http://www.gbw114.com/Library/LibraryDetail/{{docId}}.html",
    "Forget": "http://www.gbw114.com/",
    "About": "http://www.gbw114.com/about.html",
    "Topic": "http://www.gbw114.com/BBS/index.html",
    "TopicDetail": "http://www.gbw114.com/bbs/topic_{{topicId}}_1.html",
    "PostTopic": "http://www.gbw114.com/BBS/TopicPosts.html?type=1",
    "product": "http://www.gbw114.com/rm/plist_1_0_0_1.html",
    "bnccdetail": "http://www.gbw114.com/pro/p8/{{classId}}/p_{{id}}.html",
    "standard": "http://www.gbw114.com/slist_3/slist_3_0_0_1.html",
    "customize": "http://www.gbw114.com/Product/SearchOpenSearchNo.html",
    "standarddetail": "http://www.gbw114.com/s_{{classId}}/s_{{id}}.html",
    "Order": "http://www.gbw114.com/User/NeiOrders.html",
    "Invoice": "http://www.gbw114.com/User/getNoApplyInvoice.html",
    "Intergral": "http://www.gbw114.com/User/CreditLogs.html",
    "Exchange": "http://www.gbw114.com/User/ExchangeProduct.html",
    "ExchangeDetail": "http://www.gbw114.com/",
    "IntergralRule": "http://www.gbw114.com/User/Integralrule.html",
    "Bill": "http://www.gbw114.com/User/getUserAccount.html",
    "UserInfo": "http://www.gbw114.com/User/UserInfo.html",
    "FootPrint": "http://www.gbw114.com/",
    "Message": "http://www.gbw114.com/",
    "Attention": "http://www.gbw114.com/{{uid}}/MyFollowUser_1.html",
    "Unsubscribe": "http://www.gbw114.com/{{uid}}/FollowMyUser_1.html",
    "Collection": "http://www.gbw114.com/User/MyCollection.html",
    "Account": "http://www.gbw114.com/User/index.html",
    "ChangeMobile": "http://www.gbw114.com/User/bindAccount.html",
    "ChangePwd": "http://www.gbw114.com/User/bindAccount.html",
    "Prize": "http://www.gbw114.com/User/getUserPrizes.html",
    "Suggest": "http://www.gbw114.com/User/Guestbook.html",
    "ShopCar": "http://www.gbw114.com/ShoppingCart/index.html",
    "SubmitOrder": "http://www.gbw114.com/ShoppingCart/Checkout",
    "OpenSearch": "http://www.gbw114.com/",
    "Personal": "http://www.gbw114.com/{{uid}}.html",
    "signIn": "http://www.gbw114.com/User/index.html",
    "Course": "http://www.gbw114.com/course",
    "CourseCommentDetail": "http://www.gbw114.com/",
    "TopicCommentDetail": "http://www.gbw114.com/",
    "videodetail": "http://www.gbw114.com/TrainingDetail/{{id}}_1_1.html",
    "livedetail": "http://www.gbw114.com/LiveDetail/{{id}}_1_1.html",
    "videoRealtime": "http://www.gbw114.com/livePlay/{{id}}.html?type=1",
    "Address": "http://www.gbw114.com/User/Address",
    "addAddress": "http://www.gbw114.com/User/Address",
    "Chat": "http://www.gbw114.com",
    "OrderDetail": "http://www.gbw114.com/User/OrderDetail?num={{num}}",
    "NeiOrderDetail": "http://www.gbw114.com/User/NeiOrderDetail?ONO={{id}}",
    "Login": "http://www.gbw114.com/",
    "InitTel": "http://www.gbw114.com/",
    "ApplyInvoice": "http://www.gbw114.com/User/getNoApplyInvoice.html",
    "Lottery": "http://www.gbw114.com/",
    "StartJob": "http://www.gbw114.com/",
    "StartJobReal": "http://www.gbw114.com/",
    "Home": "http://www.gbw114.com/",
    "Mine": "http://www.gbw114.com/User/index.html",
    "Search": "http://www.gbw114.com/",
    "Error": "http://www.gbw114.com/",
    "Instrument": "http://www.gbw114.com/instrument/"
}
